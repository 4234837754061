<template>
  <validation-observer ref="observer">
    <v-form enctype="multipart/form-data" class="mb-3">
      <h3>Crea notifica</h3>
      <v-row>
        <v-col cols="12" sm="4" md="4">
          <v-text-field
            label="Contenuto nome"
            v-model="filenameCheck"
            type="text"
            class="form-control"
            name="filenameCheck"
            hide-details
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <validation-provider
            v-slot="{ errors }"
            name="users"
            rules="required"
          >
            <!-- Utente -->
            <v-autocomplete
              item-value="id"
              item-text="email"
              :loading="usersLoading"
              v-model="selectedUsers"
              :items="users"
              :search-input.sync="updateUsers"
              cache-items
              label="Utenti da notificare"
              clearable
              multiple
              small-chips
              deletable-chips
            ></v-autocomplete>
            <div
              v-for="error in errors"
              :key="error"
              class="alert alert-danger red--text"
              role="alert"
            >
              Selezionare almeno un utente
            </div>
          </validation-provider>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-btn color="primary" @click.prevent="save" class="mr-1"
            >Aggiungi</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </validation-observer>
</template>

<script>
import _ from "lodash";
import { required } from "vee-validate/dist/rules";
import {
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
  extend,
} from "vee-validate";

setInteractionMode("eager");

extend("required", required);
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      updateUsers: null,
      usersLoading: false,
    };
  },
  watch: {
    updateUsers: _.debounce(async function (val) {
      val && this.searchusers(val);
    }, 500),
  },
  methods: {
    async save() {
      let isValid = await this.$refs.observer.validate();
      if (!isValid) return;
      await this.$store.dispatch(
        "notificationsView/uploadNotificationForm/save"
      );
      // Wait until the models are updated in the UI
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
    async searchusers(q) {
      this.usersLoading = true;
      try {
        await this.$store.dispatch(
          `notificationsView/uploadNotificationForm/users/search`,
          q && typeof q === "string" ? q : null
        );
      } catch (ex) {
        console.error(ex);
      } finally {
        this.usersLoading = false;
      }
    },
  },
  computed: {
    users() {
      return this.$store.getters[
        "notificationsView/uploadNotificationForm/users/list"
      ];
    },
    filenameCheck: {
      get() {
        return this.$store.state.notificationsView.uploadNotificationForm
          .filenameCheck;
      },
      set(value) {
        this.$store.commit(
          "notificationsView/uploadNotificationForm/setFilenameCheck",
          value
        );
      },
    },
    selectedUsers: {
      get() {
        return this.$store.state.notificationsView.uploadNotificationForm
          .selectedUsers;
      },
      set(value) {
        this.$store.commit(
          "notificationsView/uploadNotificationForm/setSelectedUsers",
          value
        );
      },
    },
  },
};
</script>

<style>
</style>
