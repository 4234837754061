<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card v-if="notification">
        <v-card-title class="headline grey lighten-2">
          Modifica utenti
        </v-card-title>

        <v-card-text>
          <p>
            Stai per modificare gli utenti segnalati quando viene caricato un
            file contenente
            <b>{{ notification.filenameCheck }}</b
            >.
          </p>
          <v-autocomplete
            item-value="id"
            item-text="email"
            :loading="usersLoading"
            v-model="selectedUsers"
            :items="users"
            :search-input.sync="updateUsers"
            cache-items
            label="Utenti da notificare"
            clearable
            small-chips
            deletable-chips
            multiple
          ></v-autocomplete>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="default" text @click="dialog = false"> Annulla </v-btn>
          <v-btn color="primary" text @click="onSaveClick"> Salva </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  data() {
    return { updateUsers: null, usersLoading: false };
  },
  watch: {
    updateUsers: _.debounce(async function (val) {
      val && this.searchUsers(val);
    }, 500),
    async notification(value) {
      await this.$store.dispatch(
        "notificationsView/users/updateList",
        value?.users || []
      );
      this.selectedUsers = _.map(value?.users || [], (u) => u.id);
    },
  },
  methods: {
    async onSaveClick() {
      await this.$store.dispatch("notificationsView/editUsers", {
        uploadNotificationId: this.notification.id,
        usersIds: this.selectedUsers,
      });
      this.dialog = false;
    },
    async searchUsers(q) {
      this.usersLoading = true;
      try {
        await this.$store.dispatch(
          `notificationsView/users/search`,
          q && typeof q === "string" ? q : null
        );
      } catch (ex) {
        console.error(ex);
      } finally {
        this.usersLoading = false;
      }
    },
  },
  computed: {
    users() {
      return this.$store.getters["notificationsView/users/list"];
    },
    dialog: {
      get() {
        return this.$store.state.notificationsView.usersDialog;
      },
      set(value) {
        this.$store.commit("notificationsView/setUsersDialog", value);
      },
    },
    notification() {
      return this.$store.getters["notificationsView/notifications/current"];
    },
    selectedUsers: {
      get() {
        return this.$store.state.notificationsView.dialogSelectedUsers;
      },
      set(value) {
        this.$store.commit("notificationsView/setDialogSelectedUsers", value);
      },
    },
  },
};
</script>

<style>
</style>