<template>
  <v-container>
    <DeleteModal />
    <UsersModal />
    <NofiticationForm />
    <h3>Elenco Notifiche</h3>
    <v-data-table
      :options.sync="tableParams"
      :server-items-length="totalItems"
      :loading="tableLoading"
      :headers="headers"
      :items="notifications"
      sort-by="filenameCheck"
      @update:must-sort="getDataFromApi"
      @update:items-per-page="getDataFromApi"
      @update:page="getDataFromApi"
      @update:sort-by="getDataFromApi"
      @update:sort-desc="getDataFromApi"
      class="elevation-1"
    >
      <template v-slot:item.users="{ item }">
        <div class="pt-3 pb-3">
          <v-chip
            v-for="user in item.users"
            :key="user.id"
            color="primary"
            class="mr-1 mb-1"
            >{{ user.email }}</v-chip
          >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-2"
                fab
                x-small
                depressed
                @click.prevent="onAddUserClick(item.id)"
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon> fa-pencil-alt </v-icon>
              </v-btn>
            </template>
            <span>Modifica utenti</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:item.remove="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2"
              fab
              x-small
              depressed
              @click.prevent="onRemoveClick(item.id)"
              color="error"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> fa-trash </v-icon>
            </v-btn>
          </template>
          <span>Rimuovi</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import _ from "lodash";
import NofiticationForm from "../components/notifications/NotificationForm";
import DeleteModal from "../components/notifications/DeleteModal";
import UsersModal from "../components/notifications/UsersModal";
export default {
  components: { NofiticationForm, DeleteModal, UsersModal },
  data() {
    return {
      updatenotifications: null,
      notificationsLoading: false,
      headers: [
        { text: "Check", value: "filenameCheck" },
        { text: "Utenti da notificare", value: "users" },
        { text: "", value: "remove", sortable: false },
      ],
    };
  },
  computed: {
    tableParams: {
      get() {
        return this.$store.state.notificationsView.tableParams;
      },
      set(value) {
        this.$store.commit("notificationsView/setTableParams", value);
      },
    },
    tableLoading: {
      get() {
        return this.$store.state.notificationsView.tableLoading;
      },
      set(value) {
        this.$store.commit("notificationsView/setTableLoading", value);
      },
    },

    totalItems() {
      return this.$store.state.notificationsView.notifications.totalItems;
    },
    notifications() {
      return this.$store.getters["notificationsView/notifications/list"];
    },
    user: {
      get() {
        return this.$store.state.notificationsView.user;
      },
      set(value) {
        this.$store.commit("notificationsView/setUser", value);
        this.getDataFromApi();
      },
    },
  },
  async mounted() {
    await this.getDataFromApi();
  },
  watch: {
    options: {
      async handler() {
        await this.getDataFromApi();
      },
      deep: true,
    },
    updateNotifications: _.debounce(async function (val) {
      val && this.searchNotifications(val);
    }, 500),
  },
  methods: {
    onRemoveClick(itemId) {
      this.$store.commit(
        "notificationsView/notifications/setCurrentId",
        itemId
      );
      this.$store.commit("notificationsView/setRemoveDialog", true);
      console.log("itemId", itemId);
    },
    onAddUserClick(itemId) {
      this.$store.commit(
        "notificationsView/notifications/setCurrentId",
        itemId
      );
      this.$store.commit("notificationsView/setUsersDialog", true);
    },
    async searchNotifications(fullName) {
      this.notificationsLoading = true;
      try {
        await this.$store.dispatch(`notificationsView/notifications/search`, {
          filenameCheck,
        });
      } catch (ex) {
        console.error(ex);
      } finally {
        this.notificationsLoading = false;
      }
    },
    async getDataFromApi() {
      await this.$store.dispatch("notificationsView/loadTableData");
    },
    onResetClick() {
      this.$store.commit("notificationsView/setFiltersData", null);
      this.getDataFromApi();
    },
    async deleteItem(item) {
      await this.$store.dispatch("notificationsView/delete", item.id);
    },
  },
};
</script>

<style>
</style>
