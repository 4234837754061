<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card v-if="notification">
        <v-card-title class="headline grey lighten-2">
          Rimuovi notifica
        </v-card-title>

        <v-card-text>
          <p>
            Stai per eliminare la notifica
            <b>{{ notification.filenameCheck }}</b
            >. Confermi di voler procedere?
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="default" text @click="dialog = false"> Annulla </v-btn>
          <v-btn color="primary" text @click="onSaveClick"> Conferma </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  methods: {
    async onSaveClick() {
      await this.$store.dispatch(
        "notificationsView/delete",
        this.notification.id
      );
      console.log("here2");

      this.dialog = false;
    },
  },
  computed: {
    dialog: {
      get() {
        return this.$store.state.notificationsView.removeDialog;
      },
      set(value) {
        this.$store.commit("notificationsView/setRemoveDialog", value);
      },
    },
    notification() {
      return this.$store.getters["notificationsView/notifications/current"];
    },
  },
};
</script>

<style>
</style>